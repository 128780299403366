<!-- 主页 -->
<template>
  <div class="main">
    <div
      style="
        height: 40px;
        background: #039eee;
        font-size: 16px;
        font-family: SimHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
      "
    >
      <img
        src="../assets/申请@2x.png"
        alt
        style="
          width: 17px;
          height: 17px;
          vertical-align: text-bottom;
          margin-left: 13px;
        "
      />
      保函申请
    </div>
    <div class="cardTop">
      <div style="display: flex; width: 100%; padding-left: 1%">
        <div class="cardTop-top">
          <div
            class="guaranteeType"
            :class="{ first: first }"
            @mouseover="mouseOver(1)"
          >
            投标保函
          </div>
          <div
            class="guaranteeType"
            :class="{ second: second }"
            @mouseover="mouseOver(2)"
          >
            履约保函
          </div>
          <div
            class="guaranteeType"
            :class="{ third: third }"
            @mouseover="mouseOver(3)"
          >
            农民工工资保函
          </div>
        </div>
        <div class="cardTop-middle" v-if="typeSelection1">
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/1@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/2@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/3@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/4@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/5@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
        </div>
        <div class="cardTop-right" v-if="typeSelection1">
          <el-button type="primary" @click="bid(1)">立即申请</el-button>
        </div>

        <div class="cardTop-middle" v-if="typeSelection2">
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/1@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/2@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/3@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/4@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/5@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
        </div>
        <div class="cardTop-right" v-if="typeSelection2">
          <el-button type="primary" @click="bid(2)">立即申请</el-button>
        </div>

        <div class="cardTop-middle" v-if="typeSelection3">
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/1@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/2@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/3@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/4@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/5@2x.png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
        </div>
        <div class="cardTop-right" v-if="typeSelection3">
          <el-button type="primary" @click="bid(3)">立即申请</el-button>
        </div>
      </div>
    </div>
    <!-- <el-card class="cardMiddle">
      <div style="display: flex;justify-content: space-around;height: 100%">
        <div class="explain">
          <div class="cardMiddleContent">
            <i class="el-icon-document"></i>
          </div>
          <div class="cardMiddlePart">
            订单总数
            <div style="font-weight: 700;font-size: 30px;">48</div>
          </div>
        </div>
        <div class="explain">
          <div class="cardMiddleContent">
            <i class="el-icon-coin"></i>
          </div>
          <div class="cardMiddlePart">
            保费总额
            <div style="font-weight: 700;font-size: 30px;">2132</div>
          </div>
        </div>
        <div class="explain">
          <div class="cardMiddleContent">
            <i class="el-icon-postcard"></i>
          </div>
          <div class="cardMiddlePart">
            未支付订单
            <div style="font-weight: 700;font-size: 30px;">12</div>
          </div>
        </div>
        <div class="explain">
          <div class="cardMiddleContent">
            <i class="el-icon-close"></i>
          </div>
          <div class="cardMiddlePart">
            订单退款中
            <div style="font-weight: 700;font-size: 30px;">1</div>
          </div>
        </div>
        <div class="explain">
          <div class="cardMiddleContent">
            <i class="el-icon-pie-chart"></i>
          </div>
          <div class="cardMiddlePart">
            生效保函
            <div style="font-weight: 700;font-size: 30px;">545</div>
          </div>
        </div>
      </div>
    </el-card>-->
    <div style="margin-top: 10px; display: flex; height: 540px">
      <div style="flex: 1; overflow: hidden; background: #fff">
        <div
          style="
            height: 40px;
            background: #039eee;
            font-size: 16px;
            font-family: SimHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 40px;
          "
        >
          <img
            src="../assets/订单 (1)@2x (1).png"
            alt
            style="
              width: 17px;
              height: 17px;
              vertical-align: text-bottom;
              margin-left: 13px;
            "
          />
          保函订单
        </div>
        <el-row :gutter="20" style="padding: 20px; padding-bottom: 0">
          <el-col :span="4">
            <el-input v-model="orderNumber" placeholder="订单编号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="projectName" placeholder="项目名称"></el-input>
          </el-col>
          <el-col :span="4">
            <el-select v-model="type" placeholder="订单状态">
              <el-option
                v-for="item in typeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <div class="block">
              <el-date-picker
                v-model="date"
                type="date"
                placeholder="申请日期"
                style="width: 100%"
              ></el-date-picker>
            </div>
          </el-col>
          <el-col :span="7">
            <el-button type="primary" @click="getDataList">搜索</el-button>
            <el-button @click="reset">重置</el-button>
            <el-button type="primary" style="margin-left: 30px" @click="more"
              >更多</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="tableData"
          style="width: 100%; padding: 20px; padding-bottom: 0"
        >
          <el-table-column
            align="center"
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="order_num"
            label="订单编号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="biaoduanName"
            label="项目名称"
          ></el-table-column>
          <el-table-column align="center" label="申请时间" width="100">
            <template slot-scope="scope">
              <p>{{ moment(scope.row.create_date).format("YYYY-MM-DD") }}</p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="gua_fee"
            label="保费（元）"
            width="100"
          ></el-table-column>
          <el-table-column align="center" label="订单状态" width="150">
            <template slot-scope="scope">
              <p v-show="scope.row.cost_id == null">未支付</p>
              <p v-show="scope.row.pay_status == 1 && scope.row.cost_id">
                已提交支付信息
              </p>
              <p v-show="scope.row.pay_status == 2 && scope.row.cost_id">
                已支付
              </p>
              <p v-show="scope.row.pay_status == 4 && scope.row.cost_id">
                待退款
              </p>
              <p v-show="scope.row.pay_status == 5 && scope.row.cost_id">
                已退款
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="保函状态" width="150">
            <template slot-scope="scope">
              <p v-show="scope.row.order_status == 1">待审核</p>
              <p v-show="scope.row.order_status == 2">系统审核</p>
              <p v-show="scope.row.order_status == 3">担保审核</p>
              <p v-show="scope.row.order_status == 4">已出函</p>
              <p v-show="scope.row.order_status == 'N'">订单拒收</p>
              <p v-show="scope.row.order_status == 'U'">订单信息错误</p>
              <p v-show="scope.row.order_status == 'C'">已撤单</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="250">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                v-if="scope.row.order_status == 4"
                @click="download(scope.row.relative_path)"
                >下载保函</el-button
              >
              <el-button
                type="text"
                size="small"
                v-if="
                  ((scope.row.pay_status == 1 && scope.row.cost_id) ||
                    (scope.row.pay_status == 2 && scope.row.cost_id)) &&
                  (scope.row.order_status == 1 ||
                    scope.row.order_status == 2 ||
                    scope.row.order_status == 'U')
                "
                @click="revocation(scope.row)"
                >撤单</el-button
              >
              <!-- <el-button type="text" size="small" v-if="scope.row.cost_id == null" @click="payment(scope.row)">立即支付</el-button> -->
              <el-button
                type="text"
                size="small"
                v-if="scope.row.cost_id == null"
                @click="immediate(scope.row)"
                >立即支付</el-button
              >
              <!-- <el-button type="text" size="small" @click="particulars(scope.row)">查看详情</el-button> -->
              <!-- <el-button type="text" size="small" @click="examine(scope.row.order_num)">邀约函</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="width: 250px; margin-left: 10px; background: #fff">
        <div
          style="
            font-size: 16px;
            font-family: SimHei;
            font-weight: bold;
            color: #ffffff;
            height: 40px;
            background: #039eee;
            line-height: 40px;
          "
        >
          <img
            src="../assets/常见问题@2x.png"
            alt
            style="
              width: 17px;
              height: 17px;
              vertical-align: text-bottom;
              margin-left: 13px;
            "
          />
          常见问题
        </div>
        <div style="padding: 20px">
          <p style="font-size: 14px; margin-top: 20px">
            <i
              class="el-popconfirm__icon el-icon-question"
              style="color: rgb(51, 126, 190); font-size: 20px"
            ></i>
            <a @click="clickTitle(1)"
              >申请保函的时候是需要先把保证金打给你们再支付保函费或者手续费吗？</a
            >
          </p>
          <p style="font-size: 14px">
            <i
              class="el-popconfirm__icon el-icon-question"
              style="color: rgb(51, 126, 190); font-size: 20px"
            ></i>
            <a @click="clickTitle(2)">需要现在办理电子保函么？</a>
          </p>
          <p style="font-size: 14px">
            <i
              class="el-popconfirm__icon el-icon-question"
              style="color: rgb(51, 126, 190); font-size: 20px"
            ></i>
            <a @click="clickTitle(3)">什么是电子保函？</a>
          </p>
          <p style="font-size: 14px">
            <i
              class="el-popconfirm__icon el-icon-question"
              style="color: rgb(51, 126, 190); font-size: 20px"
            ></i>
            <a @click="clickTitle(4)">保函什么时间能出？</a>
          </p>
          <p style="font-size: 14px">
            <i
              class="el-popconfirm__icon el-icon-question"
              style="color: rgb(51, 126, 190); font-size: 20px"
            ></i>
            <a @click="clickTitle(5)">延长天数什么意思？</a>
          </p>
          <p style="font-size: 14px">
            <i
              class="el-popconfirm__icon el-icon-question"
              style="color: rgb(51, 126, 190); font-size: 20px"
            ></i>
            <a @click="clickTitle(6)">在哪可以下载保函？</a>
          </p>
        </div>
        <div>
          <div
            style="
              font-size: 16px;
              font-family: SimHei;
              font-weight: bold;
              color: #ffffff;
              height: 40px;
              background: #039eee;
              line-height: 40px;
            "
          >
            <img
              src="../assets/帮助中心 (1)@2x.png"
              alt
              style="
                width: 17px;
                height: 17px;
                vertical-align: text-bottom;
                margin-left: 13px;
              "
            />
            帮助
          </div>
          <div style="padding-left: 20px; padding-top: 20px">
            <i
              class="el-icon-s-order"
              style="color: rgb(51, 126, 190); font-size: 20px"
            ></i>
            <el-button
              type="text"
              style="color: #333; margin-left: 5px"
              @click="operationManual"
              >操作手册</el-button
            >
          </div>
          <div style="padding-left: 20px">
            <i
              class="el-icon-phone"
              style="color: rgb(51, 126, 190); font-size: 20px"
            ></i>
            <el-button
              type="text"
              style="color: #333; margin-left: 5px; cursor: default"
              >400-183-1833</el-button
            >
          </div>
          <div style="padding-left: 20px">
            <el-button
              type="text"
              style="color: #333; margin-left: 5px; cursor: default"
              >业务咨询：15633598882</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 常见问题弹窗 -->
    <el-dialog title="常见问题" :visible.sync="commonProblem" width="30%">
      <h3 ref="h3"></h3>
      <div ref="div"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commonProblem = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- <el-drawer title="保函详情" :visible.sync="drawer" size="70%" @closed="closed">
      <div style="padding: 0 50px;display: flex;justify-content: space-between;">
        <div class="backletter">
          <div style="text-align: center;font-size: 22px;">投标保函</div>
          <div style="text-align: right;margin-right: 20px;">
            保函编号:
            <span class="pivotal">以保函实际编号为准</span>
          </div>
          <p>申请人：{{this.form.warrantee}}</p>
          <p>地址：</p>
          <p>受益人：{{this.form.beneficiary}}</p>
          <p>地址：宿州市</p>
          <p>开立人：河北永信正德非融资担保有限公司</p>
          <p>地址：石家庄高新区珠峰大街169号想象国际北区1-1-904</p>
          <p>
            致：
            <span class="pivotal">{{this.form.beneficiary}}</span>（受益人名称）
          </p>
          <p class="text">
            我方（即“开立人”）已获得通知，本保函申请人（即“投标人”）已响应贵方就
            <span class="pivotal">{{this.form.projectName}}</span>（以下简称“本工程”）发出的招标文件，并已向招标人（即“受益人”）提交了投标文件（即“基础交易”）。
          </p>
          <p class="text">
            一、我方理解根据招标条件，投标人必须提交一份投标保函（以下简称“本保函”），
            以担保投标人诚信履行其在上述基础交易中承担的投标人义务。鉴此，应申请人要求，
            我方在此同意向贵方出具此投标保函，本保函担保金额最高不超过人民币（大写）
            <span
              class="pivotal"
            >{{this.form.gua_money_upper}}</span>
            （
            <span class="pivotal">{{this.form.money}}元</span>）。
          </p>
          <p class="text">二、我方在投标人发生以下情形时承担保证担保责任：</p>
          <p class="text">（1）投标人在开标后和投标有效期满之前撤销投标的；</p>
          <p class="text">（2）投标人在收到中标通知后，不能或拒绝在中标通知书规定的时间内与贵方签订合同；</p>
          <p class="text">（3）投标人在与贵方签订合同后，未在规定的时间内提交符合招标文件要求的履约担保；</p>
          <p class="text">（4）投标人违反招标文件规定的其他情形。</p>
          <p class="text">
            三、本保函为不可撤销、不可转让的见索即付独立保函。本保函有效期自开立之日起至投标有效期届满之日后的30日。投标有效期延长的，本保函有效期相应顺延，最迟不超过
            <span
              class="pivotal"
            >{{this.form.bid_end_date}}</span>。
          </p>
          <p class="text">四、我方承诺，在收到受益人发来的书面付款通知后的15日内无条件支付，前述书面付款通知即为付款要求之单据，且应满足以下要求：</p>
          <p class="text">（1）付款通知到达的日期在本保函的有效期内；</p>
          <p class="text">（2）载明要求支付的金额；</p>
          <p class="text">（3）载明申请人违反招投标文件规定的义务内容和具体条款；</p>
          <p class="text">（4）声明不存在招标文件规定或我国法律规定免除申请人或我方支付责任的情形；</p>
          <p class="text">
            （5）书面付款通知应在本保函有效期内到达的地址是：
            <span
              class="pivotal"
            >石家庄高新区珠峰大街169号想象国际北区1-1-904 联系人：师美琦 电话：0311-85341904。</span>
          </p>
          <p class="text">受益人发出的书面付款通知应由其为鉴明受益人法定代表人（负责人）或授权代理人签字并加盖公章。</p>
          <p class="text">五、本保函项下的权利不得转让，不得设定担保。贵方未经我方书面同意转让本保函或其项下任何权利，对我方不发生法律效力。</p>
          <p class="text">六、本保函项下的基础交易不成立、不生效、无效、被撤销、被解除，不影响本保函的独立有效。</p>
          <p
            class="text"
          >七、受益人应在本保函到期后的七日内将本保函正本退回我方注销，但是不论受益人是否按此要求将本保函正本退回我方，我方在本保函项下的义务和责任均在保函有效期到期后自动消灭。</p>
          <p class="text">
            八、本保函适用的法律为中华人民共和国法律，争议裁判管辖地为中华人民共和国
            <span class="pivotal">石家庄市</span>。
          </p>
          <p class="text">九、本保函自我方法定代表人或授权代表签字并加盖公章之日起生效。</p>
          <p>
            开立人：
            <span class="pivotal">河北永信正德非融资担保有限公司</span>（公章）
          </p>
          <p>
            法定代表人（或授权代表）：
            <span class="pivotal">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span> （签字）
          </p>
          <p>
            地址：
            <span class="pivotal">石家庄高新区珠峰大街169号想象国际北区1-1-904</span>
          </p>
          <p>
            邮政编码：
            <span class="pivotal">050000</span>
          </p>
          <p>
            电话：
            <span class="pivotal">0311-85341904</span>
          </p>
          <p>
            传真：
            <span class="pivotal">0311-85341904</span>
          </p>
          <p>开立时间：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日</p>
        </div>
        <div class="information">
          <div>
            <span style="margin-left: 20px;color: #333;font-weight: 700;">
              <i class="el-icon-more" style="transform: rotate(90deg);"></i>项目信息
            </span>
            <el-form
              ref="form"
              :model="form"
              label-width="120px"
              style="margin-top: 20px;width: 90%;"
            >
              <el-form-item label="项目名称">
                <el-input v-model="form.projectName" disabled></el-input>
              </el-form-item>
              <el-form-item label="受益人">
                <el-input v-model="form.beneficiary" disabled></el-input>
              </el-form-item>
              <el-form-item label="被保证人">
                <el-input v-model="form.warrantee" disabled></el-input>
              </el-form-item>
              <el-form-item label="担保金额">
                <el-input v-model="form.money" disabled></el-input>
              </el-form-item>
              <el-form-item label="开标日期">
                <el-input v-model="form.bidOpeningDate" disabled></el-input>
              </el-form-item>
              <el-form-item label="投标有效期">
                <el-input v-model="form.validity" disabled></el-input>
              </el-form-item>
              <el-form-item label="保函延长期">
                <el-input v-model="form.lengthen" disabled></el-input>
              </el-form-item>
              <el-form-item label="有效期">
                <el-input v-model="form.bid_end_date" disabled></el-input>
              </el-form-item>
              <el-form-item label="保函状态">
                <el-input v-model="form.state" disabled></el-input>
              </el-form-item>
              <span style="margin-left: 20px;margin-top: 20px;color: #333;font-weight: 700;">
                <i class="el-icon-more" style="transform: rotate(90deg);"></i>订单信息
              </span>
              <el-form-item label="产品类型" style="margin-top: 20px;">
                <el-input v-model="form.type" disabled></el-input>
              </el-form-item>
              <el-form-item label="订单金额">
                <el-input v-model="form.orderAmount" disabled></el-input>
              </el-form-item>
              <el-form-item label="下单日期">
                <el-input v-model="form.createData" disabled></el-input>
              </el-form-item>
              <el-form-item label="订单状态">
                <el-input v-model="form.orderStatus" disabled></el-input>
              </el-form-item>
              <el-form-item label="操作">
                <el-button
                  size="small"
                  v-if="(form.orderStatus == '已支付' || form.orderStatus == '已提交支付信息') && (form.state == '待审核' || form.state == '系统审核' || form.state == '订单信息错误')"
                  @click="revocation"
                >撤单</el-button>
                <el-button size="small" v-if="this.form.orderStatus == '未支付'" @click="immediate">立即支付</el-button>
                <el-button size="small" v-if="this.form.orderStatus == '未支付'" @click="payment">立即支付</el-button>
                <el-button
                  size="small"
                  v-if="this.form.state == '已出函' && this.form.ticket_status == null"
                  @click="invoice = true"
                >申请发票</el-button>
                <el-button
                  size="small"
                  v-if="this.form.ticket_status != null"
                  @click="checkDetails"
                >申请发票详情</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </el-drawer> -->
    <el-dialog
      title="保函邀约函预览"
      :visible.sync="dialogFormVisible"
      width="50%"
      top="7vh"
    >
      <iframe
        :src="'https://www.haneton.com/guarantee/' + filePath"
        frameborder="0"
        style="width: 100%; height: 600px"
      ></iframe>
      <div style="text-align: center; margin-top: 20px">
        <el-checkbox v-model="checked">我已阅读并同意该邀约函内容</el-checkbox>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button type="primary" :disabled="!checked" @click="immediate"
          >支付</el-button
        >
      </div>
    </el-dialog>

    <el-drawer title="发票申请" :visible.sync="invoice" size="50%">
      <div
        style="
          padding: 20px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        "
      >
        <span>请选择开票类型：</span>
        <div style="display: flex; margin-top: 20px; margin-bottom: 20px">
          <el-card
            shadow="hover"
            style="cursor: pointer"
            :class="{ elCard: isActive }"
            @click.native="common"
            >增值税普通发票</el-card
          >
          <el-card
            shadow="hover"
            style="margin-left: 20px; cursor: pointer"
            :class="{ elCard: isLine }"
            @click.native="dedicated"
            >增值税专用发票</el-card
          >
        </div>
      </div>
      <div style="padding: 30px">
        <div v-if="typeCommon">
          <div style="margin-bottom: 30px">开票信息：</div>
          <el-form
            ref="ticket"
            :model="ticket"
            label-width="130px"
            style="margin-top: 20px; width: 50%"
            :rules="ticketObj"
          >
            <el-form-item label="企业名称">
              <el-input v-model="ticket.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="税号">
              <el-input v-model="ticket.paragraph" disabled></el-input>
            </el-form-item>
            <div style="margin-top: 40px; margin-bottom: 30px">
              发票接收信息：
            </div>
            <el-form-item label="邮寄地址" prop="mail">
              <el-input v-model="ticket.mail"></el-input>
            </el-form-item>
            <el-form-item label="接收人" prop="person">
              <el-input v-model="ticket.person"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="contact">
              <el-input v-model="ticket.contact"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱" prop="electronic">
              <el-input v-model="ticket.electronic"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitTicket('ticket')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div v-if="typeDedicated">
          <div style="margin-bottom: 30px">开票信息：</div>
          <el-form
            ref="major"
            :model="major"
            label-width="130px"
            style="margin-top: 20px; width: 50%"
            :rules="majorObj"
          >
            <el-form-item label="企业名称">
              <el-input v-model="major.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="税号">
              <el-input v-model="major.paragraph" disabled></el-input>
            </el-form-item>
            <el-form-item label="企业地址" prop="site">
              <el-input v-model="major.site"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="telephone">
              <el-input v-model="major.telephone"></el-input>
            </el-form-item>
            <el-form-item label="开户行名称" prop="opening">
              <el-input v-model="major.opening"></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="account">
              <el-input v-model="major.account"></el-input>
            </el-form-item>
            <el-form-item label="一般纳税人登记表">
              <span class="befor_1">*</span>
              <el-upload
                class="upload-demo"
                action="api/newGuarantee.do?callBidFileUpLoad"
                name="taxpayerRegisterFile"
                :on-success="success"
              >
                <el-button>上传文件</el-button>
              </el-upload>
            </el-form-item>
            <div style="margin-top: 40px; margin-bottom: 30px">
              发票接收信息：
            </div>
            <el-form-item label="邮寄地址" prop="area">
              <el-input v-model="major.area"></el-input>
            </el-form-item>
            <el-form-item label="接收人" prop="consignee">
              <el-input v-model="major.consignee"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="major.phone"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱" prop="email">
              <el-input v-model="major.email"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitMajor('major')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="申请详情" :visible.sync="details" size="50%">
      <div
        style="
          padding: 20px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        "
        v-if="this.detailsOnTheInvoice.ticket_type == 1"
      >
        <p style="text-indent: 2em">发票类型：增值税普通发票</p>
        <p style="text-indent: 2em">
          申请时间：{{ this.detailsOnTheInvoice.create_date }}
        </p>
      </div>
      <div
        style="padding: 20px; border-bottom: 1px solid #ccc"
        v-if="this.detailsOnTheInvoice.ticket_type == 1"
      >
        <p style="text-indent: 2em">开票信息</p>
        <p style="text-indent: 4em">
          企业名称：{{ this.detailsOnTheInvoice.ticket_title }}
        </p>
        <p style="text-indent: 6em">
          税号：{{ this.detailsOnTheInvoice.tax_number }}
        </p>
      </div>
      <div
        style="padding: 20px; border-bottom: 1px solid #ccc"
        v-if="this.detailsOnTheInvoice.ticket_type == 1"
      >
        <p>发票邮寄信息</p>
        <p style="text-indent: 4em">
          邮寄地址：{{ this.detailsOnTheInvoice.receive_address }}
        </p>
        <p style="text-indent: 5em">
          接收人：{{ this.detailsOnTheInvoice.receive_name }}
        </p>
        <p style="text-indent: 4em">
          联系电话：{{ this.detailsOnTheInvoice.receive_phone }}
        </p>
        <p style="text-indent: 4em">
          电子邮箱：{{ this.detailsOnTheInvoice.address_mail }}
        </p>
      </div>

      <div
        style="
          padding: 20px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        "
        v-if="this.detailsOnTheInvoice.ticket_type == 2"
      >
        <p style="text-indent: 2em">发票类型：增值税专用发票</p>
        <p style="text-indent: 2em">
          申请时间：{{ this.detailsOnTheInvoice.create_date }}
        </p>
      </div>
      <div
        style="padding: 20px; border-bottom: 1px solid #ccc"
        v-if="this.detailsOnTheInvoice.ticket_type == 2"
      >
        <p style="text-indent: 2em">开票信息</p>
        <p style="text-indent: 4em">
          企业名称：{{ this.detailsOnTheInvoice.ticket_title }}
        </p>
        <p style="text-indent: 6em">
          税号：{{ this.detailsOnTheInvoice.tax_number }}
        </p>
        <p style="text-indent: 4em">
          企业地址：{{ this.detailsOnTheInvoice.address_company }}
        </p>
        <p style="text-indent: 6em">
          电话：{{ this.detailsOnTheInvoice.phone_company }}
        </p>
        <p style="text-indent: 3em">
          开户行名称：{{ this.detailsOnTheInvoice.khh }}
        </p>
        <p style="text-indent: 6em">
          账号：{{ this.detailsOnTheInvoice.bank_account }}
        </p>
        <p>
          一般纳税人登记表：
          <el-button size="small" @click="registration">查看</el-button>
        </p>
      </div>
      <div
        style="padding: 20px; border-bottom: 1px solid #ccc"
        v-if="this.detailsOnTheInvoice.ticket_type == 2"
      >
        <p>发票邮寄信息</p>
        <p style="text-indent: 4em">
          邮寄地址：{{ this.detailsOnTheInvoice.receive_address }}
        </p>
        <p style="text-indent: 5em">
          接收人：{{ this.detailsOnTheInvoice.receive_name }}
        </p>
        <p style="text-indent: 4em">
          联系电话：{{ this.detailsOnTheInvoice.receive_phone }}
        </p>
        <p style="text-indent: 4em">
          电子邮箱：{{ this.detailsOnTheInvoice.address_mail }}
        </p>
      </div>
    </el-drawer>

    <!-- 支付页面 -->
    <el-dialog
      title="支付页"
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <iframe
        :src="this.content"
        frameborder="0"
        width="100%"
        height="620px"
      ></iframe>
    </el-dialog>
    <el-dialog
      title="系统升级维护通知"
      :visible.sync="centerDialogVisible"
      width="38%"
      center
    >
      <div style="font-size: 18px">
        <p>尊敬的用户：</p>
        <p style="text-indent: 2em">
          为提供更好的服务，将定于03月17日（周四）PM 09：00- PM
          12：00进行系统升级维护，届时相关业务会关闭服务, 无法进行操作，系统升级对您造成的不便，敬请谅解。
        </p>
        <p style="text-indent: 2em">如有需要请联系：156 3359 8882</p>
        <!-- <p style="text-indent: 2em">公司放假安排：1月30日--2月6日</p>
        <p style="text-indent: 2em">正式上班时间：2月7日</p>
        <p style="text-indent: 2em">放假期间如需咨询可留言客服，客服看到后将在上班后第一时间回复您，由此带来的不便，敬请原谅！</p>
        <p style="text-indent: 2em;color:#ff0000">提醒：银行保函于1.27日最后一天出函!三点之后支付的于2.8日出函！2月中旬开标项目请于放假前申请保函，以免影响您的出函时间！</p>
        <p style="text-indent: 27em;margin-top:10px;">河北筑正科技有限公司</p>
        <p style="text-indent: 30em">2022年1月26日</p> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里导入其他文件（组件，工具js，第三方插件js，json文件，图片文件等等）
//import 《组件名称》 from '《组件路径》';
import downloadjs from "downloadjs";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  // 组件名称
  name: "",

  // 这里存放数据
  data() {
    return {
      centerDialogVisible: false,
      orderNumber: null,
      projectName: null,
      typeSelection1: true,
      typeSelection2: false,
      typeSelection3: false,
      first: true,
      second: false,
      third: false,
      type: null,
      typeArr: [
        {
          value: 0,
          label: "未支付",
        },
        {
          value: 1,
          label: "已提交支付信息",
        },
        {
          value: 2,
          label: "已支付",
        },
        {
          value: 4,
          label: "待退款",
        },
        {
          value: 5,
          label: "已退款",
        },
      ],
      date: null,
      tableData: [],
      drawer: false,
      form: {
        projectName: "",
        beneficiary: "",
        warrantee: "",
        money: "",
        bidOpeningDate: "",
        validity: "",
        lengthen: "",
        state: "",
        type: "",
        orderAmount: "",
        createData: "",
        orderStatus: "",
        omit_relative_path: "",
        gua_money_upper: "",
        bid_end_date: "",
        ticket_status: "",
        tendering_file_url: "",
        omit_file_url: "",
      },
      invoice: false,
      typeCommon: false,
      typeDedicated: false,
      isActive: false,
      isLine: false,
      ticket: {
        name: "",
        paragraph: "",
        mail: "",
        person: "",
        contact: "",
        electronic: "",
      },
      ticketObj: {
        mail: [
          { required: true, message: "邮寄地址不可为空", trigger: "blur" },
        ],
        person: [
          { required: true, message: "接收人不可为空", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "联系电话不可为空", trigger: "blur" },
        ],
        electronic: [
          { required: true, message: "电子邮箱不可为空", trigger: "blur" },
        ],
      },
      major: {
        name: "",
        paragraph: "",
        site: "",
        telephone: "",
        opening: "",
        account: "",
        area: "",
        consignee: "",
        phone: "",
        email: "",
        registerUrl: "",
        registerUrlName: "",
      },
      majorObj: {
        site: [
          { required: true, message: "企业地址不可为空", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "电话不可为空", trigger: "blur" },
        ],
        opening: [
          { required: true, message: "开户行名称不可为空", trigger: "blur" },
        ],
        account: [{ required: true, message: "账号不可为空", trigger: "blur" }],
        area: [
          { required: true, message: "邮寄地址不可为空", trigger: "blur" },
        ],
        consignee: [
          { required: true, message: "接收人不可为空", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "联系电话不可为空", trigger: "blur" },
        ],
        email: [
          { required: true, message: "电子邮箱不可为空", trigger: "blur" },
        ],
      },
      details: false,
      firmName: "",
      CAKeyEncStr: "",
      dialogVisible: false,
      content: "",
      orderNum: "",
      ticket_type: "",
      detailsOnTheInvoice: {
        create_date: "",
        ticket_title: "",
        tax_number: "",
        receive_address: "",
        receive_name: "",
        receive_phone: "",
        address_mail: "",
        address_company: "",
        phone_company: "",
        khh: "",
        bank_account: "",
      },
      commonProblem: false,
      dialogFormVisible: false,
      checked: false,
      filePath: "",
    };
  },

  // 监听属性 类似于data概念
  computed: {},

  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    // 点击更多
    more() {
      this.$router.push("/history");
    },

    // 顶部保函点击立即创建
    bid(e) {
      if (e == 1) {
        this.$router.push("/online");
      } else if (e == 2) {
        this.$router.push("/agreement");
      } else {
        this.$router.push("/salary");
      }
    },

    // 鼠标进入事件
    mouseOver(e) {
      if (e == 1) {
        this.typeSelection1 = true;
        this.first = true;
        this.second = false;
        this.third = false;
        this.typeSelection2 = false;
        this.typeSelection3 = false;
      } else if (e == 2) {
        this.typeSelection2 = true;
        this.second = true;
        this.first = false;
        this.third = false;
        this.typeSelection1 = false;
        this.typeSelection3 = false;
      } else {
        this.typeSelection3 = true;
        this.third = true;
        this.first = false;
        this.second = false;
        this.typeSelection1 = false;
        this.typeSelection2 = false;
      }
    },

    // 获取数据列表
    getDataList() {
      var date = "";
      if (this.date == "" || this.date == null) {
        date = null;
      } else {
        date = this.moment(this.date).format("YYYY-MM-DD");
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?findGuaranteeApplyHistory",
        params: {
          CAKeyId: this.firmName,
          from: 10,
          page: 1,
          pageNum: 5,
          projectName: this.projectName,
          orderNum: this.orderNumber,
          applyDate: date,
          payStatus: this.type,
        },
      }).then((res) => {
        this.tableData = res.data.data.items;
      });
    },

    // 点击重置
    reset() {
      this.projectName = "";
      this.orderNumber = "";
      this.date = "";
      this.type = "";
      this.getDataList();
    },

    // 点击下载保函
    download(e) {
      var relative_path = e.replace(/\\/g, "/");
      // var guaLoadHead = "http://39.98.152.102:8891/guarantee/" + relative_path;
      var guaLoadHead = "https://www.haneton.com/guarantee/" + relative_path;
      downloadjs(guaLoadHead);
    },

    getEnterpriseMessage() {
      var type = window.sessionStorage.getItem("type");
      if (type == "CA") {
        var params = {
          CAKeyId: this.firmName,
          from: 10,
          CAKeyEncStr: this.CAKeyEncStr,
        };
      } else if (type == "CFCA") {
        var params = {
          CAKeyId: this.firmName,
          from: 10,
          CAKeyEncStr: window.sessionStorage.getItem("content"),
        };
      } else {
        var params = {
          handsetNum: window.sessionStorage.getItem("phone"),
          from: 10,
        };
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?existEnterpriseInfo",
        params: params,
      }).then((res) => {
        if (
          res.data.data.userStatus == "1" ||
          (res.data.data.userStatus == "3" && !res.data.data.apply)
        ) {
          this.$confirm("企业信息未完善,请先完善企业信息", "提示", {
            confirmButtonText: "确定",
            closeOnPressEscape: false,
            closeOnClickModal: false,
            closeOnHashChange: false,
            showCancelButton: false,
            showClose: false,
            type: "warning",
          }).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        } else if (res.data.data.userStatus == "3" && res.data.data.apply) {
          this.$confirm(
            res.data.data.errorInfo + "，请先完善企业信息",
            "提示",
            {
              confirmButtonText: "确定",
              closeOnPressEscape: false,
              closeOnClickModal: false,
              closeOnHashChange: false,
              showCancelButton: false,
              showClose: false,
              type: "warning",
            }
          ).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        }
      });
    },

    // 点击查看详情
    particulars(e) {
      if (window.sessionStorage.getItem("type") == "account") {
        this.form.warrantee = window.sessionStorage.getItem("com_name");
      } else {
        this.form.warrantee = this.firmName;
      }
      this.orderNum = e.order_num;
      this.form.ticket_status = e.ticket_status;
      this.form.projectName = e.biaoduanName;
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?findManageInfo",
        params: {
          orderNum: e.order_num,
        },
      }).then((res) => {
        var item = res.data.data.items[0];
        this.form.beneficiary = item.profits_person;
        this.form.money = item.gua_money;
        this.form.bidOpeningDate = item.bid_begin_date;
        this.form.validity = item.bid_validity;
        this.form.lengthen = item.yancahng_date;
        if (e.order_status == 1) {
          this.form.state = "待审核";
        } else if (e.order_status == 2) {
          this.form.state = "系统审核";
        } else if (e.order_status == 3) {
          this.form.state = "担保审核";
        } else if (e.order_status == 4) {
          this.form.state = "已出函";
        } else if (e.order_status == "N") {
          this.form.state = "订单拒收";
        } else if (e.order_status == "U") {
          this.form.state = "订单信息错误";
        } else if (e.order_status == "C") {
          this.form.state = "已撤单";
        }
        this.form.type = "投标保函";
        this.form.orderAmount = e.gua_fee;
        this.form.createData = this.moment(item.create_date).format(
          "YYYY-MM-DD"
        );
        if (e.cost_id == null) {
          this.form.orderStatus = "未支付";
        } else if (e.pay_status == 1 && e.cost_id) {
          this.form.orderStatus = "已提交支付信息";
        } else if (e.pay_status == 2 && e.cost_id) {
          this.form.orderStatus = "已支付";
        } else if (e.pay_status == 4 && e.cost_id) {
          this.form.orderStatus = "待退款";
        } else if (e.pay_status == 5 && e.cost_id) {
          this.form.orderStatus = "已退款";
        }
        this.form.omit_relative_path = item.omit_relative_path;
        this.form.gua_money_upper = item.gua_money_upper;
        this.form.bid_end_date = item.bid_end_date;
        this.form.tendering_file_url = item.tendering_file_url;
        this.form.omit_file_url = item.omit_file_url;
      });
      this.drawer = true;
    },

    // 点击邀约函查看
    examine(e) {
      this.axios({
        method: "GET",
        url: "/hetInterfaceController.do?showYyhFile",
        params: {
          orderNum: e,
        },
      }).then((res) => {
        window.open("https://www.haneton.com/guarantee/" + res.data.obj);
      });
    },

    // 点击查看招标文件
    callForBids() {
      var tendering = this.form.tendering_file_url.replace(/\\/g, "/");
      var str =
        "https://www.haneton.com/hetinterface/hetInterfaceController.do?getPdfFile" +
        "&filePath=" +
        tendering;
      window.open(str, "_blank");
    },

    // 点击查看补遗文件
    addendum() {
      var tendering = this.form.omit_file_url.replace(/\\/g, "/");
      var str =
        "https://www.haneton.com/hetinterface/hetInterfaceController.do?getPdfFile" +
        "&filePath=" +
        tendering;
      window.open(str, "_blank");
    },

    // 点击撤单
    revocation(e) {
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?cancelGuaranteeOrder",
        params: {
          orderNum: e.order_num,
          CAKeyId7: this.firmName,
          CAKeyId0: window.sessionStorage.getItem("com_name"),
        },
      }).then((res) => {
        if (res.data.status == 0) {
          this.$message({
            message: "撤单成功",
            type: "success",
          });
          // this.drawer = false;
          this.getDataList();
        } else {
          this.$message({
            message: res.data.statusInfo,
            type: "error",
          });
        }
      });
    },
    payment(e) {
      this.orderNum = e.order_num;
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?findInvitationLette",
        params: {
          orderNum: this.orderNum,
          from: 10,
          CAKeyId: this.firmName,
        },
      }).then((res) => {
        if (res.data.status == 0) {
          this.filePath = res.data.data.relative_path;
          this.dialogFormVisible = true;
        } else {
          this.$message({
            message: res.data.statusInfo,
            type: "error",
          });
        }
      });
    },

    // 点击立即支付
    immediate(e) {
      this.orderNum = e.order_num;
      var CAType = "10_3";
      this.content =
        "https://www.haneton.com/hetinterface/hetInterfaceController.do?goPay4Gs&orderNum=" +
        this.orderNum +
        "&CAUkeyId=" +
        this.firmName +
        "&comId=A01A08&guaFee=" +
        this.form.money +
        "&fromType=" +
        10 +
        "&CAType=" +
        CAType +
        "&guaType=" +
        2;
      this.dialogFormVisible = false;
      this.dialogVisible = true;
    },

    // 点击普通发票
    common() {
      this.isLine = false;
      this.typeDedicated = false;
      this.typeCommon = !this.typeCommon;
      this.isActive = !this.isActive;
    },

    // 点击专用发票
    dedicated() {
      this.isActive = false;
      this.typeCommon = false;
      this.typeDedicated = !this.typeDedicated;
      this.isLine = !this.isLine;
    },

    // 普通发票点击提交
    submitTicket(ticket) {
      this.$refs[ticket].validate((valid) => {
        if (valid) {
          // 成功
          this.axios({
            method: "GET",
            url: "/newGuarantee.do?addTicketApply",
            params: {
              companyName: this.ticket.name,
              creditCode: this.ticket.paragraph,
              receiveAddress: this.ticket.mail,
              receiveName: this.ticket.person,
              receivePhone: this.ticket.contact,
              emailAddress: this.ticket.electronic,
              ticketType: 1,
              orderNum: this.orderNum,
              moneyAmount: "0",
              companyAccount: "",
            },
          }).then((res) => {
            if (res.data.status == 0) {
              this.$message({
                message: "申请发票成功",
                type: "success",
              });
              this.invoice = false;
              this.drawer = false;
              this.getDataList();
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          // 失败
          return false;
        }
      });
    },

    // 专用发票上传文件
    success(response) {
      if (response.success == true) {
        this.$message({
          message: "上传文件成功!",
          type: "success",
        });
        this.major.registerUrl = response.attributes.filePath;
        this.major.registerUrlName = response.obj;
      } else {
        this.$message({
          message: "上传文件失败，" + response.msg,
          type: "error",
        });
      }
    },

    // 专用发票点击提交
    submitMajor(major) {
      this.$refs[major].validate((valid) => {
        if (valid) {
          // 成功
          this.axios({
            method: "GET",
            url: "/newGuarantee.do?addTicketApply",
            params: {
              companyName: this.major.name,
              creditCode: this.major.paragraph,
              receiveAddress: this.major.area,
              receiveName: this.major.consignee,
              receivePhone: this.major.phone,
              emailAddress: this.major.email,
              ticketType: 2,
              orderNum: this.orderNum,
              moneyAmount: "0",
              companyAccount: this.major.account,
              companyAddress: this.major.site,
              companyPhone: this.major.telephone,
              companyKhh: this.major.opening,
              registerUrl: this.major.registerUrl,
              registerUrlName: this.major.registerUrlName,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              this.$message({
                message: "申请发票成功",
                type: "success",
              });
              this.invoice = false;
              this.drawer = false;
              this.getDataList();
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          // 失败
          return false;
        }
      });
    },

    // 点击申请发票详情
    checkDetails() {
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?findTicketInfo",
        params: {
          orderNum: this.orderNum,
        },
      }).then((res) => {
        if (res.data.data.items[0].ticket_type == "普票") {
          this.detailsOnTheInvoice.ticket_type = 1;
          this.detailsOnTheInvoice.create_date = this.moment(
            res.data.data.items[0].create_date
          ).format("YYYY-MM-DD");
          this.detailsOnTheInvoice.ticket_title =
            res.data.data.items[0].ticket_title;
          this.detailsOnTheInvoice.tax_number =
            res.data.data.items[0].tax_number;
          this.detailsOnTheInvoice.receive_address =
            res.data.data.items[0].receive_address;
          this.detailsOnTheInvoice.receive_name =
            res.data.data.items[0].receive_name;
          this.detailsOnTheInvoice.receive_phone =
            res.data.data.items[0].receive_phone;
          this.detailsOnTheInvoice.address_mail =
            res.data.data.items[0].address_mail;
        } else {
          this.detailsOnTheInvoice.create_date = this.moment(
            res.data.data.items[0].create_date
          ).format("YYYY-MM-DD");
          this.detailsOnTheInvoice.ticket_title =
            res.data.data.items[0].ticket_title;
          this.detailsOnTheInvoice.tax_number =
            res.data.data.items[0].tax_number;
          this.detailsOnTheInvoice.receive_address =
            res.data.data.items[0].receive_address;
          this.detailsOnTheInvoice.receive_name =
            res.data.data.items[0].receive_name;
          this.detailsOnTheInvoice.receive_phone =
            res.data.data.items[0].receive_phone;
          this.detailsOnTheInvoice.address_mail =
            res.data.data.items[0].address_mail;
          this.detailsOnTheInvoice.ticket_type = 2;
          this.detailsOnTheInvoice.address_company =
            res.data.data.items[0].address_company;
          this.detailsOnTheInvoice.phone_company =
            res.data.data.items[0].phone_company;
          this.detailsOnTheInvoice.khh = res.data.data.items[0].khh;
          this.detailsOnTheInvoice.bank_account =
            res.data.data.items[0].bank_account;
          this.detailsOnTheInvoice.register_file_url =
            res.data.data.items[0].register_file_url;
        }
        this.details = true;
      });
    },

    // 点击一般纳税人登记表查看
    registration() {
      var tendering = this.detailsOnTheInvoice.register_file_url.replace(
        /\\/g,
        "/"
      );
      var str =
        "https://www.haneton.com/hetinterface/hetInterfaceController.do?getPdfFile" +
        "&filePath=" +
        tendering;
      window.open(str, "_blank");
    },

    // 查看详情关闭回调
    closed() {
      this.form.projectName = "";
      this.form.beneficiary = "";
      this.form.warrantee = "";
      this.form.money = "";
      this.form.bidOpeningDate = "";
      this.form.validity = "";
      this.form.lengthen = "";
      this.form.state = "";
      this.form.type = "";
      this.form.orderAmount = "";
      this.form.createData = "";
      this.form.orderStatus = "";
      this.form.omit_relative_path = "";
      this.form.gua_money_upper = "";
      this.form.bid_end_date = "";
      this.form.ticket_status = "";
      this.form.tendering_file_url = "";
      this.form.omit_file_url = "";
    },

    // 点击常见问题标题
    clickTitle(e) {
      this.commonProblem = true;
      setTimeout(() => {
        if (e == 1) {
          this.$refs.h3.innerText =
            "问：申请保函的时候是需要先把保证金打给你们再支付保函费或者手续费吗？";
          this.$refs.div.innerText =
            "答：申请保函的话是不用给我们打投标保证金的，这两种方式您选其一就行，申请保函的话相当于是已经交了投标保证金了，只是我们会根据保证金金额，按比例收取保函费用（不退还）。";
        } else if (e == 2) {
          this.$refs.h3.innerText = "问：需要现在办理电子保函么？";
          this.$refs.div.innerText =
            "答：是否需要办理电子保函是需要您根据您所投项目的招标文件中的要求，招标文件上规定使用电子保函，您登录我们平台即可申请保函，且为了保证您的保函使用，建议您在开标日前三个工作日进行申请保函。";
        } else if (e == 3) {
          this.$refs.h3.innerText = "问：什么是电子保函？";
          this.$refs.div.innerText =
            "答：为了保证甲方权宜，一般招标文件中会要求投标方缴纳投标保证金到招标代理公司，投标保函是投标保证金的一种表现形式，能够替代现金保证金，也就是说您不需要再给招标代理公司交这个项目的现金保证金了，您办一份保函，支付保函服务费即可，达到为企业减负的目的。电子保函是根据国家政策顺应而生的，现在招投标全流程电子化的趋势已经形成，为了投标人更方便申请保函，确保保函的真实性，推出线上申请保函的方式。";
        } else if (e == 4) {
          this.$refs.h3.innerText = "问：保函什么时间能出？";
          this.$refs.div.innerText =
            "答：一般来说担保公司保函在当天申请并且17:00前支付保函费用，当天六点之前出函。";
        } else if (e == 5) {
          this.$refs.h3.innerText = "问：延长天数什么意思？";
          this.$refs.div.innerText =
            "答：保函到期日=开标日期当日+投标有效期+保函延长期，您需要根据招标文件对投标保证金的要求，如果招标文件没有对投标保证金有不能延长的要求，但为了确保项目出现延期的情况，还仍然能使用这份保函，建议您延长30天，具体能否延长您可以和您的招代公司确定。";
        } else {
          this.$refs.h3.innerText = "问：在哪可以下载保函？";
          this.$refs.div.innerText =
            "答: 登录平台在历史保函管理中查看保函进度并且可以直接下载。";
        }
      }, 0);
      // this.$refs.adadadad.innerText = "1231123123"
    },

    // 操作手册
    operationManual() {
      window.open("https://ejlb673kl8.feishu.cn/wiki/wikcnjVAIfeV1fZkcPvyY0lhHGd")
      // var operationManualPdf = "https://www.suzhou.haneton.com/manualsSZ.pdf";
      // downloadjs(operationManualPdf);
    },
    handleClose() {
      this.dialogVisible = false;
      this.$router.push("/history");
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.firmName = window.sessionStorage.getItem("firmName");
    this.CAKeyEncStr = window.sessionStorage.getItem("date");
    this.ticket.paragraph = window.sessionStorage.getItem("socialCode");
    this.major.paragraph = window.sessionStorage.getItem("socialCode");
    this.ticket.name = window.sessionStorage.getItem("com_name");
    this.major.name = window.sessionStorage.getItem("com_name");
    this.getDataList();
    this.getEnterpriseMessage();
  },

  // 生命周期 - 创建之前
  beforeCreate() {},

  // 生命周期 - 挂载之前
  beforeMount() {},

  // 生命周期 - 更新之前
  beforeUpdate() {},

  // 生命周期 - 更新之后
  updated() {},

  // 生命周期 - 销毁之前
  beforeDestroy() {},

  // 生命周期 - 销毁完成
  destroyed() {},

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // keep-alive缓存离开之后触发
  deactivated() {},
};
</script>

<style scoped>
.main {
  height: 100%;
}
.cardTop {
  background-color: #fff;
  height: 246px;
  display: flex;
  flex: 1;
  align-items: center;
}
.cardTop-top {
  width: 150px;
}
.cardTop-middle {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.cardTop-right {
  width: 150px;
  text-align: center;
  line-height: 122px;
}
.guaranteeType {
  text-align: right;
  padding-right: 10%;
  color: #333;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  width: 88%;
  cursor: default;
  border-right: 2px solid #e4e7ed;
}
.content {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 1px solid rgb(51, 126, 190);
  text-align: center;
  color: rgb(51, 126, 190);
}
.explain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.part {
  text-align: center;
  font-size: 12px;
  color: rgb(51, 126, 190);
}
.cardMiddle {
  border-radius: 10px;
  margin-top: 10px;
  height: 162px;
}
.cardMiddleContent {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 2px solid rgb(51, 126, 190);
  line-height: 70px;
  text-align: center;
  color: rgb(51, 126, 190);
  font-size: 40px;
}
.cardMiddlePart {
  text-align: center;
  font-size: 16px;
  color: rgb(51, 126, 190);
}
a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
a:hover,
a:visited,
a:link,
a:active {
  color: #333;
}
.backletter {
  width: 50%;
  height: 800px;
  overflow-y: auto;
  line-height: 30px;
}
.information {
  width: 50%;
  height: 800px;
  border-left: 1px solid #ccc;
  overflow-y: auto;
}
.el-form-item {
  margin-bottom: 12px;
}
.first {
  background-color: #dbeaee;
  color: #3c9bb7;
  border-right: 2px solid #3c9bb7;
}
.second {
  background-color: #dbeaee;
  color: #3c9bb7;
  border-right: 2px solid #3c9bb7;
}
.third {
  background-color: #dbeaee;
  color: #3c9bb7;
  border-right: 2px solid #3c9bb7;
}
.elCard {
  background-color: rgba(51, 126, 190, 1) !important;
  color: #fff;
}
.text {
  text-indent: 2em;
}
.pivotal {
  text-decoration: underline;
}
.befor_1 {
  position: absolute;
  left: -137px;
  color: #f56c6c;
}
</style>